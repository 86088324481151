import React from 'react';
import './Size_Dropdown.css';

const Size_Dropdown = ({ handleSizeChange, selectedSize, productCategory }) => {
  // Determine available sizes based on the product category
  const sizes = productCategory === 'Oversized-fit' ? ['S', 'M', 'L'] : ['M', 'L', 'XL', 'XXL'];
  console.log(productCategory)

  return (
    <div className="size-selection-container">
      <h3>Select Size:</h3>
      <div className="size-options">
        {sizes.map((size) => (  
          <label key={size} className="">
            <input
              type="radio"
              name="size"
              value={size}
              checked={selectedSize === size}
              onChange={handleSizeChange}
              className="hidden"
              id={`size-${size}`}
            />
            <label
              htmlFor={`size-${size}`}
              className={`size-label ${
                selectedSize === size ? 'selected' : ''
              }`}
            >
              {size}
            </label>
          </label>
        ))}
      </div>
    </div>
  );
};

export default Size_Dropdown;
