import { baseApi } from './baseApi';
import { PAYMENT_URL} from '../constant';

export const PaymentApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    initiatePayment: builder.mutation({
      query: (paymentDetails) => ({
        url: PAYMENT_URL,
        method: 'POST',
        body:paymentDetails
      }),
    }),
  }),
  overrideExisting: false,
});

export const { useInitiatePaymentMutation } = PaymentApi;