import {configureStore} from '@reduxjs/toolkit'
import { baseApi } from './api/baseApi'
import productReducer from './reducers/productReducer'
import userReducer from './reducers/userReducer'
import { userApi } from './api/userApi'


const store=configureStore({
    reducer:{
        [baseApi.reducerPath]:baseApi.reducer,
        [userApi.reducerPath]: userApi.reducer,
        product:productReducer,
        user:userReducer,    
    },
    middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware),
    devTools:true
})

export default store