import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useGetAddressQuery } from "../../actions/api/userApi";
import { useGetOrdersMutation } from "../../actions/api/orderApi";
import { toast } from "react-toastify";
import "./OrderHistory.css";
import Loader from "../../component/Loader/Loader";

const OrderHistory = () => {
  const { user } = useSelector((state) => state.user);
  const { data: addressData, isLoading: isAddressLoading, refetch: refetchAddress } = useGetAddressQuery(user?.data?.id);
  const [getOrders, { data: orderData, isLoading: isOrderLoading, error: orderError }] = useGetOrdersMutation();

  useEffect(() => {
    // Refetch address data when component mounts
    refetchAddress();
  }, [refetchAddress]);

  useEffect(() => {
    async function fetchOrders() {
      try {
        if (user && user.data.email) {
          await getOrders({ email: user.data.email }).unwrap();
        }
      } catch (err) {
        console.error("Failed to fetch orders:", err);
        toast.error("Failed to fetch orders. Please try again later.");
      }
    }
    fetchOrders();
  }, [user, getOrders]);

  const renderAddress = (address) => {
    if (!address) return "No address provided";
    return (
      <div>
        <p>{address.name}</p>
        <p>{address.address}</p>
        <p>
          {address.city}, {address.state} {address.zip}
        </p>
        <p>{address.country}</p>
        <p>{address.phone}</p>
      </div>
    );
  };

  const renderOrders = () => {
    if (isOrderLoading) {
      return <Loader />;
    }
    if (orderError) {
      return <div>Error loading orders.</div>;
    }

    if (orderData && orderData?.data.length > 0) {
      return (
        <div className="order-history">
          {orderData?.data.map((order) => (
            <div key={order._id} className="order-card">
              <div className="order-status">Order Status: {order.orderStatus}</div>
              {order?.orderItems.map((item, index) => (
                <div key={order._id + index} className="order-item">
                  <img src={item.image} alt={item.name} className="item-image" />
                  <div className="item-details">
                    <div className="item-id">Order Id: {item._id}</div>
                    <div className="item-name">Name: {item.name}</div>
                    <div className="item-price">Price: {item.price}</div>
                    <div className="item-size">Size: {item.size}</div>
                    <div className="item-quantity">Qty: {item.quantity}</div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      );
    } else {
      return (
        <div className="order-box">
          <h3>
            <i className="fa-solid fa-circle-check fa-lg"> &nbsp; </i>
            <a href="/">
              <span>MAKE YOUR FIRST ORDER</span>
            </a>
            &nbsp; You haven't placed any orders yet.
          </h3>
        </div>
      );
    }
  };

  return (
    <div>
      {isAddressLoading ? (
        <div className="load">
          <Loader />
        </div>
      ) : (
        <>
          <div className="account-box">
            <h1>ACCOUNT</h1>
            <h4>
              Hello <span>{user?.data?.name || "User"},</span>
            </h4>
            <h3>ORDER HISTORY</h3>
          </div>

          {renderOrders()}
          <div className="table-box">
            <h1>User Details</h1>
            <table className="user-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Address</th>
                </tr>
              </thead>
              <tbody className="address-content">
                <tr>
                  <td>{user?.data?.name || ""}</td>
                  <td>{user?.data?.email || ""}</td>
                  <td>{renderAddress(addressData?.address)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </>
      )}
    </div>
  );
};

export default OrderHistory;
