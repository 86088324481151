import React, { useState } from 'react';
import './Pagination.css';

const Pagination = ({ productsPerPage, totalProducts, paginate, currentPage }) => {
  const pageNumbers = [];
  const maxPagesToShow = 5;

  for (let i = 1; i <= Math.ceil(totalProducts / productsPerPage); i++) {
    pageNumbers.push(i);
  }

  const startIndex = Math.max(0, currentPage - 1 - Math.floor(maxPagesToShow / 2));
  const endIndex = Math.min(startIndex + maxPagesToShow, pageNumbers.length);
  const displayedPages = pageNumbers.slice(startIndex, endIndex);

  const handlePageClick = (pageNumber) => {
    paginate(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <nav className="pagination">
        <ul className="pagination-list">
          {currentPage > 1 && (
            <li className="pagination-item">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handlePageClick(currentPage - 1);
                }}
                href="#"
                className="pagination-link"
              >
                Back
              </a>
            </li>
          )}
          {displayedPages.map(number => (
            <li key={number} className={`pagination-item ${currentPage === number ? 'active' : ''}`}>
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handlePageClick(number);
                }}
                href="#"
                className="pagination-link"
              >
                {number}
              </a>
            </li>
          ))}
          {currentPage < pageNumbers.length && (
            <li className="pagination-item">
              <a
                onClick={(e) => {
                  e.preventDefault();
                  handlePageClick(currentPage + 1);
                }}
                href="#"
                className="pagination-link"
              >
                Next
              </a>
            </li>
          )}
        </ul>
      </nav>
    </>
  );
};

export default Pagination;
