import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setAddress } from "../../actions/reducers/userReducer";
import { useAddingAddressMutation } from "../../actions/api/userApi";
import { toast } from "react-toastify";
import "./Address.css";
import { Helmet } from 'react-helmet';

const Address = ({ setActiveComponent }) => {
  const { userAddress, user } = useSelector((state) => state.user);
  const [addingAddress, { isLoading, error }] = useAddingAddressMutation();
  const userId = user.data.id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    phone: '',
  });

  const [errors, setErrors] = useState({
    name: '',
    city: '',
    state: '',
    zip: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    let errorMsg = '';

    if (name === 'name' || name === 'city' || name === 'state') {
      const alphabetRegex = /^[A-Za-z\s]*$/;
      if (!alphabetRegex.test(value)) {
        errorMsg = 'Only alphabets allowed';
      }
    } else if (name === 'zip' || name === 'phone') {
      const numericRegex = /^[0-9]*$/;
      if (!numericRegex.test(value)) {
        errorMsg = 'Only numbers allowed';
      }
      if (name === 'phone' && value.length > 10) {
        errorMsg = 'Phone number must be 10 digits';
      }
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: errorMsg,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    const formDataWithId = { ...formData, userId };
    try {
      const response = await addingAddress({ credentials: formDataWithId }).unwrap();
      console.log('Address added successfully:', response);
      dispatch(setAddress(formData));
      toast.success("Address added successfully", {
        position: "top-center",
      });
      navigate('/userAccount');
      setActiveComponent('dashboard');
    } catch (err) {
      console.error('Error:', err);
      toast.error("Failed to add address. Please try again.");
    }
  };

  return (
    <div className="container">
      <h2>Billing Address Details</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">Full Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            className={errors.name ? 'error-input' : ''}
          />
          {errors.name && <span className="error">{errors.name}</span>}
        </div>
        <div className="form-group">
          <label htmlFor="address">Address</label>
          <input
            type="text"
            id="address"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="city">City</label>
          <input
            type="text"
            id="city"
            name="city"
            value={formData.city}
            onChange={handleChange}
            required
            className={errors.city ? 'error-input' : ''}
          />
          {errors.city && <span className="error">{errors.city}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="state">State</label>
          <input
            type="text"
            id="state"
            name="state"
            value={formData.state}
            onChange={handleChange}
            required
            className={errors.state ? 'error-input' : ''}
          />
          {errors.state && <span className="error">{errors.state}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="zip">Zip Code</label>
          <input
            type="text"
            id="zip"
            name="zip"
            value={formData.zip}
            onChange={handleChange}
            required
            className={errors.zip ? 'error-input' : ''}
          />
          {errors.zip && <span className="error">{errors.zip}</span>}
        </div>

        <div className="form-group">
          <label htmlFor="country">Country</label>
          <select
            id="country"
            name="country"
            value={formData.country}
            onChange={handleChange}
            required
          >
            <option value="india">India</option>
            <option value="usa">United States</option>
            <option value="canada">Canada</option>
            <option value="uk">United Kingdom</option>
            <option value="australia">Australia</option>
            <option value="other">Other</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="phone">Phone Number</label>
          <input
            type="text"
            id="phone"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            className={errors.phone ? 'error-input' : ''}
          />
          {errors.phone && <span className="error">{errors.phone}</span>}
        </div>

        <button type="submit" className="submit-btn">Submit</button>
      </form>
    </div>
  );
};

export default Address;
