import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  products: [],
  searchedProducts:null,
  cart: [],
  length:''
};

const productReducer = createSlice({
  name: 'product',
  initialState,
  reducers: {
    searching: (state, action) => {
      state.searchedProducts = action.payload;
    },
    removeSearching:(state)=> {
      state.searchedProducts = null
    },
    addingProducts: (state, action) => {
      state.products = action.payload;
    },
    addTocart: (state, action) => {
      const product = action.payload;
      const existingProduct = state.cart.find(item => item._id === product._id);
      if (existingProduct) {
        existingProduct.quantity += product.quantity;
      } else {
        state.cart.push(product);
      }
    },
    removeItem: (state, action) => {
      state.cart = state.cart.filter(item => item.product._id !== action.payload);
    },
    increaseItemQuantity: (state, action) => {

      const existingProduct = state.cart.find(item =>item.product._id === action.payload);
      if (existingProduct) {
        existingProduct.quantity += 1; 
      }
    },
    
    decreaseItemQuantity: (state, action) => {
      const existingProduct = state.cart.find(item => item.product._id === action.payload);
      if (existingProduct && existingProduct.quantity > 1) {
        existingProduct.quantity -= 1;
      }
    },
    updateCart: (state, action) => {
      state.cart = action.payload;
    },
    updateLength:(state,action)=>{
      state.length=action.payload 
    }
  }
});

export const { addingProducts,searching,removeSearching, addTocart, removeItem, increaseItemQuantity, decreaseItemQuantity,updateCart,updateLength} = productReducer.actions;
export default productReducer.reducer;
