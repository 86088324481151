import React from 'react';
import { Link } from 'react-router-dom';

const Sidebar= ({isOpen,toggleDrawer,handleProductClick,searchProducts,searchedProducts,handleInputChange,searchHandler,isLoggedIn}) => {
  

  return (
    <div>
      <div className="text-center"></div>
      {/* Drawer */}
      <div
        id="drawer-contact"
        className={`fixed top-0 left-0 z-40 h-screen p-4 overflow-y-auto transition-transform ${isOpen ? '' : '-translate-x-full'} bg-white w-80 dark:bg-gray-800 drawer`}
        tabIndex="-1"
        aria-labelledby="drawer-contact-label"
      >
        <button
          type="button"
          onClick={toggleDrawer}
          className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 absolute top-2.5 end-2.5 inline-flex items-center justify-center dark:hover:bg-gray-600 dark:hover:text-white"
        >
          <svg
            className="w-3 h-3"
            aria-hidden="true"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 14 14"
          >
            <path
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
            />
          </svg>
          <span className="sr-only">Close menu</span>
        </button>
        <form className="mb-6" onSubmit={searchHandler}>
          <div className="mb-6">
            <div className="flex justify-evenly">
              <div className="mr-5">
                <input
                  type="text"
                  className="drawer-search bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500  max-w-full sm:max-w-md dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Find your T-shirt..."
                  value={searchProducts}
                  onChange={handleInputChange}
                />
              </div>       
            </div>
          </div>
        </form>
        {searchProducts && searchedProducts.length > 0 && (
              <ul className="search-results">
                {searchedProducts.map((item) => (
                  <li
                    key={item.id}
                    onClick={() => handleProductClick(item.name)}
                  >
                    {item.name.charAt(0).toUpperCase() +
                      item.name.slice(1).toLowerCase()}
                  </li>
                ))}
              </ul>
            )}
        <Link to={isLoggedIn ? "/userAccount" : "/login"} onClick={toggleDrawer} className="flex gap-2">
          <i className="fa-solid fa-user mt-1"></i>
          <p>Profile</p>
        </Link>
        <br />
        <Link to="/cart" onClick={toggleDrawer} className="flex gap-2">
          <i className="fa-solid fa-cart-shopping mt-1"></i>
          <p>Cart</p>
        </Link>
      </div>

      {/* Button to open/close drawer */}
      
    </div>
  );
};

export default Sidebar;
