import React, { useState, useEffect } from "react";
import "./Register.css"; // Assuming you will add styles here
import Loader from "../../component/Loader/Loader";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useRegisterUserMutation } from "../../actions/api/userApi";
import { login } from "../../actions/reducers/userReducer";
import { Helmet } from "react-helmet";

const Register = () => {
  const [registerUser, { isLoading }] = useRegisterUserMutation();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading(true); // Set loading to true whenever filteredProducts or buttonType changes
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the timeout as needed

    return () => clearTimeout(timer); // Clean up the timer
  }, []);

  const handleRegister = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      toast.error("Passwords do not match");
      return;
    }
    const userdetails = {
      name,
      password,
      email,
    };
    try {
      const res = await registerUser(userdetails).unwrap();
      dispatch(login(res));
      toast.success("Successfully registered");
      navigate("/");
    } catch (err) {
      console.error("Failed to register:", err);
      toast.error(err.data.message);
    }
  };

  return (
    <>
    <Helmet>
        <title>Register at Kanime Store</title>
        <meta
          name="description"
          content="Register at Kanime Store to create your account and start shopping. Join us today for exclusive deals and collections."
        />
        <meta name="keywords" content="kanime store register, kanime clothing signup, create account kanime" />
        <link rel="canonical" href="https://www.kanime.store/register" />
      </Helmet>
      {isLoading ? (
        <div className="loading">
          <Loader />
        </div>
      ) : (
        <div className="register-container">
          <form className="register-form" onSubmit={handleRegister}>
            <h2 className="register-title">Register</h2>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                id="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="confirm-password">Confirm Password</label>
              <input
                type="password"
                id="confirm-password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </div>
            <button type="submit" className="register-button">
              Register
            </button>
          </form>
        </div>
      )}
    </>
  );
};

export default Register;
