import { baseApi } from './baseApi';
import { ORDER_URL} from '../constant';

export const OrderApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.mutation({
      query: (email) => ({
        url: ORDER_URL,
        method: 'POST',
        body:email
      }),
    }),  
  }),
  overrideExisting: false,
});

export const { useGetOrdersMutation } = OrderApi;