import { baseApi } from './baseApi';
import { LOGIN_URL,REGISTER_URL, ADDRESS_URL_POSTING, ADDRESS_URL_GETTING, FORGETPASSWORD_URL, RESET_URL } from '../constant';

export const userApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    loginUser: builder.mutation({
      query: (credentials) => ({
        url: LOGIN_URL,
        method: 'POST',
        body: credentials,
      }),
    }),
    registerUser: builder.mutation({
        query: (credentials) => ({
          url: REGISTER_URL,
          method: 'POST',
          body: credentials,
        }),
      }),
    addingAddress: builder.mutation({
      query: ({credentials}) => ({
        url: ADDRESS_URL_POSTING,
        method: 'POST',
        body: credentials,
      }),
    }), 
    getAddress: builder.query({
      query: (id) => ({
        url: `/${ADDRESS_URL_GETTING}/${id}`,
        method: 'GET',
      }),
    }), 
    forgetPassword: builder.mutation({
      query: (email) => ({
        url: FORGETPASSWORD_URL,
        method: 'POST',
        body: email,
      }),
    }),
    resetPassword: builder.mutation({
      query: ({password,id}) => ({
        url: `/${RESET_URL}/${id}`,
        method: 'PUT',
        body: {password} 
      }),
    }),
  }),
  overrideExisting: false,
});

export const {useResetPasswordMutation,useLoginUserMutation ,useRegisterUserMutation,useAddingAddressMutation ,useGetAddressQuery,useForgetPasswordMutation} = userApi;