import React from "react";
import "./ForgotModal.css";
import { useState } from "react";
import { toast } from "react-toastify";


const ForgotModal = ({ handleCloseModal, isModalOpen ,handleSubmit,setForgetMail,forgetMail}) => {
    // const [forgetPassword, { isLoading, isError, isSuccess, error }] = useForgetPasswordMutation();
   
  return (
    <>
      {/* Main modal */}
      {isModalOpen && (
        <div className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-900 bg-opacity-50">
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={handleCloseModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div >
                <form  className="flex flex-col w-auto m-auto p-5 forgot-modal">
                  <label htmlFor="email">Enter your registered email!</label>
                  <input
                    type="email"
                    value={forgetMail}
                    onChange={(e)=>setForgetMail(e.target.value)}
                    required
                  />
                  <button className="forgot" type="submit" onClick={handleSubmit}>
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ForgotModal;
