import React from "react";
import "./Marquee.css";

const Marquee = () => {
  return (
    <div className="marquee-box">
        <marquee direction="left"  scrollamount="12">
        <img
            src="/Images/naruto.png"
            alt="Image 1"
            className="inline-block h-full mx-2"
          />
         
          <img
            src="/Images/Jujutsu.png"
            alt="Image 2"
            className="inline-block h-full mx-2"
          />
          <img
            src="/Images/dragon.png"
            alt="Image 3"
            className="inline-block h-full mx-2"
          />
          <img
            src="/Images/demon.png"
            alt="Image 4"
            className="inline-block h-full mx-2 demon"
          />
          <img
            src="/Images/one.png"
            alt="Image 5"
            className="inline-block h-full mx-2"
          />
          <img
            src="/Images/bleach.png"
            alt="Image 6"
            className="inline-block h-full mx-2"
          />
        </marquee>
    </div>
  );
};

export default Marquee;
