import React from 'react'
import ScrollToTop from '../../component/ScrollToTop/ScrollToTop'
import { Helmet } from 'react-helmet';

const Privacy = () => {
  return (
    <>
    <Helmet>
        <title>Privacy Policy | Kanime</title>
        <meta name="description" content="Learn about Kanime's privacy policy, how we handle your data, and our commitment to protecting your privacy when using our website." />
        <meta name="keywords" content="Kanime, Privacy Policy, Data Protection, Online Store, User Privacy" />
      </Helmet>
    <ScrollToTop/>
    <div className='total'>
        <div className='box'>
        <h1>PRIVACY POLICY</h1>
      <h3>PLEASE READ THIS TERMS OF SERVICE AGREEMENT CAREFULLY. BY USING THIS WEBSITE OR ORDERING PRODUCTS FROM THIS WEBSITE YOU AGREE TO BE BOUND BY ALL OF THE TERMS AND CONDITIONS OF THIS AGREEMENT.</h3>
      <p>This Terms of Service Agreement (the “Agreement”) governs your use of this website, www.kanimé.in , Kanimé offer of products for purchase on this Website, or your purchase of products available on this Website. This Agreement includes, and incorporates by this reference, the policies and guidelines referenced below. Kanimé reserves the right to change or revise the terms and conditions of this Agreement at any time by posting any changes or a revised Agreement on this Website. Kanimé will alert you that changes or revisions have been made by indicating on the top of this Agreement the date it was last revised. The changed or revised Agreement will be effective immediately after it is posted on this Website. Your use of the Website following the posting any such changes or of a revised Agreement will constitute your acceptance of any such changes or revisions. Kanimé encourages you to review this Agreement whenever you visit the Website to make sure that you understand the terms and conditions governing use of the Website. This Agreement does not alter in any way the terms or conditions of any other written agreement you may have with Kanimé for other products or services. If you do not agree to this Agreement (including any referenced policies or guidelines), please immediately terminate your use of the Website. If you would like to print this Agreement, please click the print button on your browser toolbar.</p>
        </div>
      
    </div>
    </>
    
  )
}

export default Privacy
