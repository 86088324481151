import React from "react";
import "./HomePage.css";
import Marquee from "../../component/Marquee/Marquee";
import { useSelector } from "react-redux";
import { useInView } from "react-intersection-observer";
import AnimationSlider from "../../component/AnimationSlider/AnimationSlider";
import { useGetProductsQuery } from "../../actions/api/productsApi";
import { useDispatch } from "react-redux";
import { addingProducts } from "../../actions/reducers/productReducer";
import { Helmet } from 'react-helmet';

const HomePage = () => {
  // Setup intersection observer hooks
  const { ref: posterRef, inView: posterInView } = useInView({ triggerOnce: true });
  const { ref: imgRef, inView: imgInView } = useInView({ triggerOnce: true });
  const { ref: textRef, inView: textInView } = useInView({ triggerOnce: true });
  const { ref: marqueeRef, inView: marqueeInView } = useInView({
    triggerOnce: true,
  });

  return (
    <>
      <Helmet>
        <title>Kanime Store | Buy Anime Clothes Online</title>
        <meta name="description" content="Shop the latest anime clothes, cosplay outfits, and merchandise at Kanime Store. Find unique anime-inspired apparel for every anime lover." />
        <meta name="keywords" content="anime clothes, anime t-shirts, cosplay outfits, anime merchandise, buy anime clothing online" />
        <link rel="canonical" href="https://www.kanime.store/" />
      </Helmet>
      <div ref={posterRef} className={`poster ${posterInView ? "animate" : ""}`}>
        <img src="/Images/poster-homepage.jpg" className="poster_image" />
      </div>
      <div
        ref={textRef}
        className={`text_center ${textInView ? "animate" : ""}`}
      >
        <h5>OTAKU'S ASSEMBLE</h5>
      </div>
      <div ref={imgRef} className={`goku-img ${imgInView ? "animate" : ""}`}>
        <img src="/Images/goku-gif.gif" className="home_image" />
      </div>
      <div
        ref={textRef}
        className={`text_center ${textInView ? "animate" : ""}`}
      >
        <h5>LIMITED EDITION</h5>
      </div>
      {/* <div className={`main-back ${carouselInView ? "animate" : ""}`}>
        <div ref={carouselRef}>
          <MyCarousel />
        </div>
      </div> */}
      <div className="animation">
        <AnimationSlider />
        <Marquee />
      </div>
      <div ref={marqueeRef}></div>
    </>
  );
};
export default HomePage;
