// export const BASE_URL='http://localhost:5000/'
export const BASE_URL='https://kanime-backend.onrender.com/'
export const LOGIN_URL='api/users/login'
export const REGISTER_URL='api/users/register'
export const PRODUCTS_URL='api/products'
export const CART_URL='api/user/cart'
export const ADDRESS_URL_POSTING='api/users/updateAddress'
export const ADDRESS_URL_GETTING='api/users/getAddress'
export const ORDER_URL='api/orders'
export const FORGETPASSWORD_URL='api/users/forgotPassword'
export const RESET_URL='api/users/resetPassword'
export const PAYMENT_URL='api/payment/orders'
export const RAZORPAY_KEY='rzp_live_dB1mdxCEyCJKu4'
