import React from "react";

const Modal = ({
  handleCloseModal,
  showModal,
  productImage,
  productPath,
  productSizeImg,
}) => {
  return (
    <>
      {/* Main modal */}
      {showModal && (
        <div className="fixed inset-0 z-50 flex justify-center items-center w-full h-full bg-gray-900 bg-opacity-50">
          <div className="relative p-4 w-full max-w-2xl max-h-full">
            {/* Modal content */}
            <div className="relative bg-white rounded-lg shadow dark:bg-gray-700">
              {/* Modal header */}
              <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-600">
                <button
                  type="button"
                  className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 ms-auto inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
                  onClick={handleCloseModal}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {/* Modal body */}
              <div className="p-4 md:p-5 space-y-4 max-h-96 overflow-y-auto">
                {/* {
                  (productImage == "size-chart"  && productSizeImg == "Oversized-fit" ? (      
                    <img src="/Images/NEW_OVERSIZED_SIZE_CHART.jpg" alt="" />
                  ) : (
                    <img src={productPath} alt="" />
                  ))
                } */}
                {productImage === "size-chart" ? (
                  productSizeImg === "Oversized-fit" ? (
                    <img
                      src="/Images/NEW_OVERSIZED_SIZE_CHART.jpg"
                      alt="Oversized Fit Size Chart"
                    />
                  ) : productSizeImg === "Regular-fit" ? (
                    <img
                      src="/Images/NEW_REGULAR_SIZE_CHART.jpg"
                      alt="Regular Fit Size Chart"
                    />
                  ) : (
                    <img
                      src="/Images/DEFAULT_SIZE_CHART.jpg"
                      alt="Default Size Chart"
                    />
                  )
                ) : (
                  <img src={productPath} alt="Product Image" />
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Modal;
