import React, { useEffect, useState } from "react";
import "./Products.css";
import { Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../component/Loader/Loader";
import Pagination from "../../component/Pagination/Pagination";
import { addingProducts } from "../../actions/reducers/productReducer";
import { useGetProductsQuery } from "../../actions/api/productsApi";
import { Helmet } from 'react-helmet';


const Products = () => {
  const { products: reducerProduct, searchedProducts } = useSelector(
    (state) => state.product
  );
  const { data:apiProducts, error, isLoading } = useGetProductsQuery();
  const dispatch=useDispatch()

  if(apiProducts){
    dispatch(addingProducts(apiProducts?.data))
  }

  
  const { search } = useLocation();
  const params = new URLSearchParams(search);
  const buttonType = params.get("buttonType");
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [productsPerPage] = useState(5);



  // Filter products based on buttonType
  const filteredProducts = reducerProduct.filter(
    (product) => product.category === buttonType
  );

  const indexOfLastProduct = currentPage * productsPerPage;
  const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  const currentProducts = (searchedProducts || filteredProducts).slice(
    indexOfFirstProduct,
    indexOfLastProduct
  );

  


  useEffect(() => {
    setLoading(true); // Set loading to true whenever filteredProducts or buttonType changes
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500); // Adjust the timeout as needed
    return () => clearTimeout(timer); // Clean up the timer
  }, [buttonType, searchedProducts, currentPage]);

  useEffect(() => {
    setCurrentPage(1); // Reset current page to 1 when buttonType changes
  }, [buttonType]);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <>
      <Helmet>
        <title>Anime Clothes & Merch | Shop Cosplay & Apparel</title>
        <meta name="description" content="Browse our wide range of anime-inspired clothing including t-shirts, hoodies, cosplay outfits, and more. Shop now at Kanime Store!" />
        <meta name="keywords" content="anime apparel, anime t-shirts, cosplay outfits, anime hoodies, anime merchandise" />
        <link rel="canonical" href="https://www.kanime.store/products" />
      </Helmet>
      <div className="title">
        <h4>{buttonType}</h4>
      </div>
      {isLoading ? (
        <div className="loading">
          <Loader />
        </div>
      ) : (
        <>
          <div className="products-container">
            {currentProducts.length > 0 ? (
              currentProducts.map((product) => (
                <Link key={product.id} to={`/product/${product.id}`}>
                  <div className="product-card">
                    <img
                      src={product.image}
                      alt={product.name}
                      className="product-image"
                    />
                    <div className="product-info">
                      <h2 className="product-name">{product.name}</h2>
                      <p className="product-price">Rs.{product.price} <span className="old-price">{product.category == "Oversized-fit" ? "RS.1399" : "RS.799"}</span></p>
                      <p className="discount-label">50% OFF</p>
                    </div>
                  </div>
                </Link>
              ))
            ) : (
              <p>No products found</p>
            )}
          </div>
          <Pagination
            productsPerPage={productsPerPage}
            totalProducts={
              searchedProducts ? searchedProducts.length : filteredProducts.length
            }
            paginate={paginate}
            currentPage={currentPage}
          />
        </>
      )}
    </>
  );
};

export default Products;
