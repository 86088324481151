import React from 'react'
import './Loader.css'

const Loader = () => {
  return (
    <div className='load'>
      <div class="loader"></div>
    </div>
  )
}

export default Loader
