import React, { useState } from "react";
import "./UserAccount.css";
import { useSelector ,useDispatch} from "react-redux";
import { useNavigate } from "react-router-dom";
import OrderHistory from "../OrderHistory/OrderHistory";
import Address from "../Address/Address";
import { logout } from "../../actions/reducers/userReducer";
import { toast } from "react-toastify";

const UserAccount = () => {
    const { user } = useSelector(state => state.user);
    const navigate = useNavigate()
    const dispatch=useDispatch()
    const [activeComponent, setActiveComponent] = useState('dashboard');

    const renderComponent = () => {
        switch (activeComponent) {
            case 'dashboard':
                return <OrderHistory />;
            case 'address':
                return <Address setActiveComponent={setActiveComponent}/>;
            default:
                return <OrderHistory />;
        }
    };
    const handleLogout = () => {
        dispatch(logout());
        navigate('/');
        toast.success("Successfully logged out");
    };

    return (
        <div className="user-box">
            <div className="sidebar">
                <div className="profile-section">
                    <img
                        src="/Images/profilelogo.png"
                        alt="Profile Picture"
                        className="profile-pic"
                    />
                    <h2 className="username">{user?.data?.name || 'kanime User'}</h2>
                </div>
                <nav className="nav-menu">
                    <a href="#dashboard" className="nav-item" onClick={() => setActiveComponent('dashboard')}>
                        <i className="fa-solid fa-border-all"></i> &nbsp;
                        Dashboard
                    </a>
                    <a href="#address" className="nav-item" onClick={() => setActiveComponent('address')}>
                        <i className="fa-solid fa-location-dot"></i> &nbsp;
                        Addresses
                    </a>
                    <button onClick={handleLogout} className="nav-item logout">
                        <i className="fa-solid fa-right-from-bracket"></i> &nbsp;
                        Logout
                    </button>
                </nav>
            </div>
            <div className="main-content">
                {renderComponent()}
            </div>
        </div>
    );
};

export default UserAccount;
