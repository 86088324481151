import { baseApi } from './baseApi';
import { CART_URL } from '../constant';

export const cartApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getCartProducts: builder.query({
      query: (id) => ({
        url: `/${CART_URL}/${id}`,
        method: 'GET',
      }),
    }),
    addCartProduct: builder.mutation({
      query: ({userId,newProduct}) => ({
        url: `/${CART_URL}/${userId}`,
        method: 'POST',
        body: newProduct,
      }),
    }),
    updateCartProduct: builder.mutation({
      query: ({ id, updatedProduct }) => ({
        url: `/${CART_URL}/${id}`,
        method: 'PUT',
        body: updatedProduct,
      }),
    }),
    deleteCartProduct: builder.mutation({
      query: ({userId,id}) => ({
        url: `/${CART_URL}/${userId}/${id}`,
        method: 'DELETE',
      }),
    }),
  }),
  overrideExisting: true,
});

export const { useGetCartProductsQuery,useDeleteCartProductMutation,useAddCartProductMutation,useUpdateCartProductMutation} = cartApi;