import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import "./Cart.css";
import Loader from "../../component/Loader/Loader";
import {
  removeItem,
  increaseItemQuantity,
  decreaseItemQuantity,
  addTocart,
  updateCart,
} from "../../actions/reducers/productReducer";
import { toast } from "react-toastify";
import {
  useGetCartProductsQuery,
  useDeleteCartProductMutation,
} from "../../actions/api/cartApi";
import { useInitiatePaymentMutation } from "../../actions/api/paymentApi";
import { RAZORPAY_KEY } from "../../actions/constant";
import axios from "axios";
import { useGetAddressQuery } from "../../actions/api/userApi";

const Cart = () => {
  const { user } = useSelector((state) => state.user);
  const { data: addressData, isLoading: isAddressLoading } = useGetAddressQuery(user?.data?.id);
  console.log(user,'userrrrr')
  console.log(addressData, 'address')
  const { data, isLoading, refetch } = useGetCartProductsQuery(user?.data?.id);
  const [initiatePayment] = useInitiatePaymentMutation();
  const { cart } = useSelector((state) => state.product);
  console.log(cart,'carttt')
  const [deleteCartProduct, { isSuccess, isError }] =
    useDeleteCartProductMutation();
  const dispatch = useDispatch();
  const [couponCode, setCouponCode] = useState("");
  const [discount, setDiscount] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
      return;
    }
    if (data && data?.cart?.length > 0) {
      dispatch(updateCart(data.cart));
    }
    refetch();
  }, [data]);

  const getTotalPrice = () => {
    return cart.reduce(
      (total, item) => total + item.product.price * item.quantity,
      0
    );
  };

  const handleIncreaseQuantity = (id) => {
    dispatch(increaseItemQuantity(id));
  };

  const handleDecreaseQuantity = (id) => {
    dispatch(decreaseItemQuantity(id));
  };

  const handleRemoveItem = async (id) => {
    try {
      await deleteCartProduct({ userId: user.data.id, id });
      dispatch(removeItem(id));
      toast.success("Product Removed");
      refetch();
    } catch (err) {
      console.log(err);
    }
  };

  const handleApplyCoupon = () => {
    const uniqueBrands = new Set(cart.map((item) => item.product.brand));
    const totalQuantity = cart.reduce((sum, item) => sum + item.quantity, 0);

    if (couponCode === "KANIMECOMBO3") {
      if (uniqueBrands.size >= 1 && totalQuantity >= 3) {
        setDiscount(198);
        toast.success("Coupon applied");
      } else {
        toast.error(
          "Coupon can only be applied if there is at least one unique brand and total quantity is 3 or more."
        );
        setDiscount(0);
      }
    } else {
      toast.error("Invalid coupon code");
      setDiscount(0);
    }
  };

  const handleRemoveCoupon = () => {
    setCouponCode("");
    setDiscount(0);
    toast.info("Coupon removed");
  };

  const handlePayment = async () => {
    if (addressData.address.address.length == 0){
      toast.info('Please add your address details')
      navigate("/address")
      return
    }
    try {
      const amount = getTotalPrice() - discount;
      const userId = user.data.id;
      const res = await initiatePayment({ amount, userId, cart }).unwrap();

      const options = {
        key:RAZORPAY_KEY,
        amount: res.amount.toString(),
        currency:res.currency,
        name: "KANIMÉ",
        description: "Order Payment",
        order_id: res.id,
        handler: async (response) => {
          try {
            const { data: verification } = await axios.post(
              "https://kanime-backend.onrender.com/api/payment/verify",
              {
                payment_id: response.razorpay_payment_id,
                order_id: response.razorpay_order_id,
                signature: response.razorpay_signature,
                userId, // Pass userId here
              }
            );
            if (verification.success) {
              await axios.post("https://kanime-backend.onrender.com/api/orders/new", {
                name: user.data.name,
                email: user.data.email,
                shippingInfo: {
                  address: addressData.address.address,
                  city:  addressData.address.city,
                  state: addressData.address.state,
                  postalCode: addressData.address.zip,
                  country: addressData.address.country,
                  pinCode: addressData.address.zip,
                  phoneNumber: addressData.address.phone,
                },
                orderItems: cart.map((item) => ({
                  product: item.product._id,
                  name: item.product.name,
                  quantity: item.quantity,
                  price: item.product.price,
                  image: item.product.images[0].url,
                  color: item.product.color,
                  size: item.selectedSize,
                })),
                paymentInfo: {
                  id: response.razorpay_payment_id,
                  status: "succeeded",
                },
                itemsPrice: getTotalPrice() - discount,
                taxPrice: 0,
                shippingPrice: 0,
                totalPrice: getTotalPrice() - discount ,
              });
              toast.success("Payment Successful! Order placed.");
              navigate("/userAccount")
            } else {
              toast.error("Payment Verification Failed");
            }
          } catch (error) {
            console.error("Error verifying payment", error);
            toast.error("Error verifying payment");
          }
        },
        prefill: {
          name: user.data.name,
          email: user.data.email,
          contact: user.data.phone,
        },
        theme: {
          color: "#3399cc",
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();

    } catch (error) {
      console.error("Error initiating payment", error);
      toast.error("Error initiating payment");
    }
  }


  return (
    <>
      {isLoading ? (
        <div className="loading">
          <Loader />
        </div>
      ) : (
        <div className="cart-container">
          <h2 className="cart-title">Shopping Cart</h2>
          {cart?.length === 0 ? (
            <p className="empty_label">Your cart is empty.</p>
          ) : (
            <>
              {cart.map((item) => (
                <div className="cart-item" key={item._id}>
                  <div className="item-info flex gap-3">
                    <div>
                      <img
                        src={item.product.images[0].url}
                        alt={item.product.name}
                        className="item-image"
                      />
                    </div>
                    <div>
                      <p className="item-name">{item.product.name}</p>
                      <div className="flex gap-5">
                        <p className="item-price">{item.product.price}</p>
                      </div>
                    </div>
                    <div>
                      <p className="item-name">SIZE</p>
                      <p className="item-size">{item.selectedSize}</p>
                    </div>
                  </div>

                  <div className="item-quantity">
                    <button
                      className="quantity-button"
                      onClick={() => handleDecreaseQuantity(item.product._id)}
                    >
                      -
                    </button>
                    <span className="quantity">{item.quantity}</span>
                    <button
                      className="quantity-button"
                      onClick={() => handleIncreaseQuantity(item.product._id)}
                    >
                      +
                    </button>
                  </div>
                  <button
                    className="remove-button"
                    onClick={() => handleRemoveItem(item.product._id)}
                  >
                    Remove
                  </button>
                </div>
              ))}
              {discount > 0 && (
                <div className="discount-info">
                  Discounted Amount: -Rs.{discount}
                </div>
              )}
              <div className="total-price">
                Total: Rs.{getTotalPrice() - discount}
              </div>

              <div className="coupon-section">
                <input
                  type="text"
                  value={couponCode}
                  onChange={(e) => setCouponCode(e.target.value)}
                  placeholder="Enter coupon code"
                />
                <div className="coupon-btn">
                  <button onClick={handleApplyCoupon}>Apply Coupon</button>
                  <button onClick={handleRemoveCoupon}>Remove Coupon</button>
                </div>
              </div>

              <div className="buy-button" onClick={handlePayment}>
                <button >Buy Now</button>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Cart;
