import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../../actions/reducers/userReducer";
import Loader from "../../component/Loader/Loader";
import ForgotModal from "../../component/ForgotModal/ForgotModal";
import { useLoginUserMutation } from "../../actions/api/userApi";
import { toast } from "react-toastify";
import "./Login.css";
import { useForgetPasswordMutation } from "../../actions/api/userApi";
import { Helmet } from "react-helmet";

const Login = () => {
  const [loginUser] = useLoginUserMutation();
  const [forgetPassword, { isLoading, isError, isSuccess, error }] = useForgetPasswordMutation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [forgetMail, setForgetMail] = useState("");

  const handleForgetModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    setLoading(true); // Set loading to true whenever filteredProducts or buttonType changes
    const timer = setTimeout(() => {
      setLoading(false);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const userDetails = {
        email,
        password,
      };
      const res = await loginUser(userDetails).unwrap();
      dispatch(login(res));
      // Save user data in session storage
      sessionStorage.setItem(
        "user",
        JSON.stringify({
          _id: res.data.id,
          name: res.data.name,
          email: res.data.email,
          address: res.data.address,
        })
      );

      toast.success("Successfully logged In");
      navigate("/");
    } catch (err) {
      toast.error(err.data.message);
      console.error("Failed to log in:", err.data);
    }
  };

  const handleSubmit = async(e) => {
    e.preventDefault();
    try {
      const res = await forgetPassword({email:forgetMail}).unwrap();
      toast.info('Link has been sent to your mail..kindly check that')
    } catch (err) {
      console.log(err,'errrr')
    }
  };

  return (
    <>
      <Helmet>
        <title>Login to Kanime Store</title>
        <meta
          name="description"
          content="Login to Kanime Store to manage your account and orders. Forgot your password? Reset it here."
        />
        <meta name="keywords" content="kanime store login, kanime clothing login, login to kanime, password reset, user login" />
        <link rel="canonical" href="https://www.kanime.store/login" />
      </Helmet>
      {loading ? (
        <div className="loading">
          <Loader />
        </div>
      ) : (
        <div className="login-container">
          <form className="login-form" onSubmit={handleLogin}>
            <h2 className="login-title">Login</h2>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </div>
            <div className="form-group">
              <label htmlFor="password">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
              />
            </div>
            <div className="button-group">
              <button type="submit" className="login-button">
                Login
              </button>
              <div>
                <h2 className="forgot-password" onClick={handleForgetModal}>
                  Forgot Password?
                </h2>
                {isModalOpen && (
                  <ForgotModal
                    handleCloseModal={handleCloseModal}
                    handleSubmit={handleSubmit}
                    isModalOpen={isModalOpen}
                    forgetMail={forgetMail}
                    setForgetMail={setForgetMail}
                  />
                )}
                <h2>New User?</h2>
              </div>
              <Link to="/register">
                <button type="button" className="signup-button">
                  Sign Up
                </button>
              </Link>
            </div>
          </form>
        </div>
      )}
    </>
  );
};

export default Login;
