import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import { useDispatch } from "react-redux";
import "./Dropdown.css";
import { removeSearching } from "../../actions/reducers/productReducer";

const Dropdown = ({setSearchProducts}) => {
  const [selectedButton, setSelectedButton] = useState(null);
  const dispatch=useDispatch()
  const navigate = useNavigate();

  const handleButtonClick = (buttonType) => {
    setSelectedButton(buttonType);
    dispatch(removeSearching())
    setSearchProducts('')
    navigate(`/products?buttonType=${buttonType}`);
  };
  
  return (
    <div className="dropdown">
      <span className="dropdown-text">T-SHIRTS <i class="fa-solid fa-caret-down"></i></span>
      <div className="dropdown-content">
        <button
          className={`dropdown-button ${selectedButton === 'Regular-fit' ? 'selected' : ''}`}
          onClick={() => handleButtonClick('Regular-fit')}
        >
          REGULAR FIT
        </button>
        <button
          className={`dropdown-button ${selectedButton === 'Oversized-fit' ? 'selected' : ''}`}
          onClick={() => handleButtonClick('Oversized-fit')}
        >
          OVERSIZED FIT
        </button>
      </div>
    </div>
  );
};

export default Dropdown;
