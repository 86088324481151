import { baseApi } from './baseApi';
import { PRODUCTS_URL} from '../constant';

export const ProductsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getProducts: builder.query({
      query: () => ({
        url: PRODUCTS_URL,
        method: 'GET',
      }),
    }),
    getProduct: builder.query({
        query: (id) => ({
          url: `${PRODUCTS_URL}/${id}`,
          method: 'GET',
        }),
      }),
  }),
  overrideExisting: false,
});

export const { useGetProductsQuery,useGetProductQuery } = ProductsApi;