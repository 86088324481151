import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
const Footer = () => {
  return (
    <>
       <Helmet>
        <title>Kanime Store | Contact & Policies</title>
        <meta
          name="description"
          content="Get in touch with Kanime Store for any inquiries. Learn more about our privacy policy, shipping policy, and terms of service. Follow us on Instagram and WhatsApp."
        />
        <meta name="keywords" content="contact Kanime Store, privacy policy, shipping policy, terms of service, follow Kanime Store, Instagram, WhatsApp" />
        <link rel="canonical" href="https://www.kanime.store/" />
      </Helmet>
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-section">
          <h2 className="footer-title">Contact Us</h2>
          <p>Email: kanimeclothing@gmail.com</p>
          <p>Phone: +91 9597243977</p>
          <p>Country of Orgin: India</p>
        </div>
        <div className="footer-section">
          <h2 className="footer-title">Quick Links</h2>
          <ul>
            <li>
              <Link to='/privacy'>Privacy Policy </Link>
            </li>
            <li>
            <Link to='/shippingPolicy'>Shipping Policy </Link>
            </li>
            <li>
            <Link to='/terms'>Terms of Service </Link>
            </li>
          </ul>
        </div>
        <div className="footer-section">
          <h2 className="footer-title">Follow Us</h2>
          <div className="footer-social-icons">
            <a
              href="https://wa.me/message/KTLH3TX5UCZ7H1"
              target="_blank"
              rel="noopener noreferrer"
              className="whatsapp"
            >
              <i className="fab fa-whatsapp fa-2xl"></i>
            </a>
            <a
              href="https://www.instagram.com/kanime.clothing?igsh=MWxmZHB0aWswZWxwdg=="
              target="_blank"
              rel="noopener noreferrer"
              className="instagram"
            >
              <i className="fab fa-instagram fa-2xl"></i>
            </a>
          </div>
        </div>
      </div>
      <div className="footer-images">
            <img src="/Images/Symbol.png" />
          </div>
      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Kanimé. All rights reserved.</p>
        <h3>Developed by <a href="https://www.instagram.com/horizon_it_solution?igsh=MWRtOXZyZzdtbnI1bg%3D%3D&utm_source=qr">Horizon IT Solutions</a></h3>
      </div>
    </footer>
    </>
  );
};

export default Footer;
