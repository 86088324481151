import HomePage from "./Pages/HomePage/HomePage";
import ShippingPolicy from "./Pages/Shipping_policy/ShippingPolicy";
import LoginPage from "./Pages/login/Login";
import RegisterPage from "./Pages/register/Register";
import Footer from "./Pages/Footer/Footer";
import { Route, Routes } from "react-router-dom";
import Header from "./Pages/Header/Header";
import Cart from "./Pages/Cart/Cart";
import Product from "./Pages/Product/Product";
import Products from "./Pages/Products/Products";
import Privacy from "./Pages/Privacy_Policy/Privacy";
import Terms from "./Pages/Terms/Terms";
import Address from "./Pages/Address/Address";
import UserAccount from "./Pages/UserAccount/UserAccount";
import Forgot from "./Pages/login/Forgot";
import { ToastContainer } from "react-toastify";
import { useGetCartProductsQuery } from "./actions/api/cartApi";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch,useSelector} from "react-redux";
import { updateLength } from "./actions/reducers/productReducer";

function App() {
  const { user } = useSelector((state) => state.user);
  const { data ,refetch} = useGetCartProductsQuery(user?.data?.id);
  const dispatch = useDispatch();
  dispatch(updateLength(data?.cart?.length))

  return (
    <>
      <div className="">
        <ToastContainer />
        <Header />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/shippingPolicy" element={<ShippingPolicy />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/product/:id" element={<Product />} />
          <Route path="/searchedProducts" element={<Products />} />
          <Route path="/privacy" element={<Privacy />} />
          <Route path="/shippingPolicy" element={<ShippingPolicy />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/address" element={<Address />} />
          <Route path="/products" element={<Products />} />
          <Route path="/userAccount" element={<UserAccount />} />
          <Route path="/forget/:id" element={<Forgot />} />
        </Routes>
        <Footer />
      </div>
    </>
  );
}

export default App;
